import * as React from "react"
// markup
const IndexPage = () => {
  return (
    <main>
      200 OK
    </main>
  )
}

export default IndexPage
